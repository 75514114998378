export const initialState={
    basket:[],
    user: null,
    shopperdetails:{}
} 

export const totalamount=(basket)=> basket?.reduce((amount,item)=>parseInt(item.rows.price)* (item.rows.count) + amount,0)
//total weight
export const totalweight=(basket)=> basket?.reduce((amount,item)=>parseInt(item.rows.weight)* (item.rows.count) + amount,0)
// number of items in cart

export const numofitems=(basket) => basket?.reduce((itemtotal,item)=>parseInt(item.rows.count) + itemtotal,0)

const reducer =( state,action)=>{
    
      switch(action.type){ 
        case  'SET_USER':
        return {
            ...state,
            user:action.user,
        }
        case  'SET_SHOPPER':
        return {
            ...state,
            shopperdetails:action.shopper,
        }
        case'RESET':
            return {
                ...state,
                basket:[...state.basket=[]]
         }
        case'ADD_TO_BASKET':{
            let addbasket=[...state.basket]
            const index =state.basket.findIndex((basketItem)=>basketItem.id===action.item.id) 
            if(index >=0){
                parseInt(addbasket[index].rows.count++)
            }
            else{
                addbasket=[...state.basket,action.item]
            }
            return {...state,basket:addbasket};      
         }
       
         case 'REMOVE_FROM_BASKET':{
            let newBasket= [...state.basket];
            const index =state.basket.findIndex((basketItem)=>basketItem.id===action.item.id)
            if(index>=0){
                newBasket.splice(index,1)
            }
            else{
                alert('cant delete')
            }
            return {...state,basket:newBasket};      
        }

        case 'INCREMENT':{
            let newBasketIncrement= [...state.basket];
            const increment_index =state.basket.findIndex((basketItem)=>basketItem.id===action.item.id)
            if(increment_index >=0){
                newBasketIncrement[increment_index].rows.count++
            }
            else{
                alert('Cant Increase Item')
            }
            return {...state,basket:newBasketIncrement};
        
        }
         case 'DECREMENT':{
            let newBasketdecrement= [...state.basket];
            const decrement_index =state.basket.findIndex((basketItem)=>basketItem.id===action.item.id)
            if(decrement_index >=0){
                newBasketdecrement[decrement_index].rows.count--
            }
            else{
                console.log('Cant Reduce Item') 
            }
            return {...state,basket:newBasketdecrement};
          
        }
        default:{
        return state; 
    }
    }
}
export default reducer